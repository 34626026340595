document.addEventListener("alpine:notification:init", (e) => {
    if (process.env.DEBUG == "true") console.debug("notifications:statusEvent set new notifications listener");
    /**
     * Create tracking event on the status of the notifications if it is changed it change the notification object in alpine
     */
    navigator.permissions
        .query({ name: "notifications" })
        .then(function (permission) {
            permission.onchange = function () {
                e.detail.NotificatNotificationStatusion = Notification.permission;
            };
        });

    Alpine.magic("NotificationColorStatus", (el) => {
        if (process.env.DEBUG == "true") console.debug("Color Status chenge");
        /**
         * Show the color in html dom in the notification button
         * if denied is red
         * if granted is green
         * if default is yellow
         */
        switch (e.detail.NotificatNotificationStatusion) {
            case "denied":
                return "color: red;";
            case "granted":
                return "color: green;";
            case "default":
                return "color: yellow;";
            default:
                return null;
        }
    });
});
