import Alpine from "alpinejs";
import AlpineI18n from "alpinejs-i18n";
import persist from "@alpinejs/persist";
window.Alpine = Alpine;
require("./language");
Alpine.plugin(persist);
Alpine.plugin(AlpineI18n);
require("./darkMode");
require("./user");
require("./notification");
require("./messages");
// require('./dialog')
Alpine.start();
