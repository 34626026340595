import { messaging, firestore } from "../firebaseApp";
import { getToken } from "firebase/messaging";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { collection, setDoc } from "firebase/firestore";
const vapidKey = process.env.VAPIDKEY;

const get_device_uuid = () => {
    if (process.env.DEBUG == "true") console.debug("notifications:getToken run get_device_uuid");
    const gen = ["iPhone", "Android", "iPod", "iPad"];
    const windows = ["OS/2", "Pocket PC", "Windows", "Win16", "Win32", "WinCE"];
    const appale = [
        "iPhone Simulator",
        "iPod Simulator",
        "iPad Simulator",
        "Macintosh",
        "MacIntel",
        "MacPPC",
        "Mac68K",
        "Pike v7.6 release 92",
        "Pike v7.8 release 517",
    ];
    const Linux = [
        "Linux",
        "Linux aarch64",
        "Linux armv5tej1",
        "Linux armv61",
        "Linux armv71",
        "Linux armv81",
        "Linux i686",
        "Linux i686 on x86_64",
        "Linux i686 X11",
        "Linux MSM8960_v3.2.1.1_N_R069_Rev:18",
        "Linux ppc64",
        "Linux x86_64",
        "Linux x86_64 X11",
    ];
    if (gen.includes(navigator.platform)) return navigator.platform;
    if (windows.includes(navigator.platform)) return "pc";
    if (appale.includes(navigator.platform)) return "pc";
    if (Linux.includes(navigator.platform)) return "Android";
};

document.addEventListener("auth:listener:login", async ({ detail }) => {
    if (process.env.DEBUG == "true") console.debug("notification:getToken:run event user:login ");
    const device = get_device_uuid();
    const currentToken = await getToken(messaging, { vapidKey: vapidKey });
    const docRef = doc(firestore, "users", detail.uid);
    // console.log(docRef);
    // const docSnap = await getDoc(docRef);
    updateDoc(docRef, { [`devices.${device}`]: currentToken });

    // const docSnap = await getDoc(docRef);
    // if (!(device in docSnap.data()["devices"]) || docSnap.data()["devices"][device] != currentToken)
    document.dispatchEvent(new CustomEvent("notification:getToken", { detail: currentToken }));
});
