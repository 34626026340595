// const getApiKey = require("../auth/apiKey").getApiKey
let AlpineUser = {
  userUid: null,
  apiKey: null,
  login: true,
  click: false,
  set user(user) {
    this.user = userData;
  },
  get user() {
    return this.userData;
  },
};
document.addEventListener("alpine:init", () => {
  AlpineUser = Alpine.reactive(AlpineUser);
  Alpine.user = AlpineUser;
  window.AlpineUser = AlpineUser;
  Alpine.data("user", () => AlpineUser);
  document.dispatchEvent(new CustomEvent("alpine:user:init", { detail: AlpineUser }))
});
