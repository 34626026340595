import { firestore } from "../firebaseApp";
import { query, onSnapshot, collection, orderBy } from "firebase/firestore";

const convetEpochToString = (time) => {
    if (process.env.DEBUG == "true") console.debug("messages:listener run convetEpochToString");
    const date = new Date(time * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`;
};


document.addEventListener("auth:listener:login", ({ detail }) => {
    if (process.env.DEBUG == "true") console.debug("messages:listener start listener ");
    const Q = query(collection(firestore, "users", detail.uid, "messags"), orderBy("EpochTime"));
    const unsubscibe = onSnapshot(Q, (snapshot) => {
        let Messages = [];
        snapshot.forEach((e) => {
            Messages.unshift({
                ...e.data(),
                TimeCreate: convetEpochToString(e.data().EpochTime.seconds),
                id: e.id,
            });
        });
        // Messages.sort((a, b) => {
            // return a.EpochTime > b.EpochTime;
        // });
        document.dispatchEvent(
            new CustomEvent("messages:listener:NewMessages", {
                detail: { messages: Messages, new: true, add: false },
            })
        );
    });
    document.dispatchEvent(
        new CustomEvent("messages:listener:unsubscibe", { detail: unsubscibe })
    );
});

document.addEventListener("messages:listener:unsubscibe", ({ detail: unsubscibe }) => {
    document.addEventListener("auth:listener:logOut", () => {
        if (process.env.DEBUG == "true") console.debug("unsubscibe messages listener");
        unsubscibe()
        document.dispatchEvent(new Event("deleteMessages"));
    });
});

