import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseApp";

onAuthStateChanged(auth, userState => {
    if (userState) {
        if (process.env.DEBUG == "true") console.debug("user login ");
        document.dispatchEvent(new CustomEvent("auth:listener:login", { detail: userState }))
    }
    else {
        if (process.env.DEBUG == "true") console.debug("user logOut ");
        document.dispatchEvent(new Event("auth:listener:logOut"))
    }
})


