import { firestore } from "../firebaseApp";
import { doc, getDoc } from "firebase/firestore";
export const getApiKey = async (uid) => {
    if (process.env.DEBUG == "true") console.debug("run function: getApiKey");
    const docRef = doc(firestore, "users", uid);
    const docSnap = await getDoc(docRef);
    return docSnap.data().apiKey;
};

document.addEventListener("alpine:user:init", ({ detail: AlpineUser }) => {
    Alpine.magic("getApiKey", async () => {
        if (process.env.DEBUG == "true") console.debug("run magic: getApiKey");
        window.setTimeout(() => { AlpineUser.click = false; }, 3000);
        AlpineUser.click = true;
        if (!AlpineUser.apiKey) AlpineUser.apiKey = await getApiKey(AlpineUser.userUid);
        navigator.clipboard.writeText(AlpineUser.apiKey);
    });

    Alpine.magic("ApiKeyTooltip", () => {
        return AlpineUser.click ? true : false;
    });
    Alpine.magic("isLogin", () => AlpineUser.login)
});