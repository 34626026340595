import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const DEFAULT_REGION = 'us-central1';

const firebaseConfig = {
  apiKey: process.env.APIKEY,
  authDomain: process.env.AUTHDOMAIN,
  projectId: process.env.PROJECTID,
  storageBucket: process.env.STORAGEBUCKET,
  messagingSenderId: process.env.MESSAGINGSENDERID,
  appId: process.env.APPID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app, process.env.APIURL ? process.env.APIURL : DEFAULT_REGION);
const localhostArray = process.env.LOCALHOST.split(",")
if (localhostArray.includes(location.hostname)) {
  if (typeof auth !== "undefined") connectAuthEmulator(auth, `http://${location.hostname}:9099`, { disableWarnings: true, });
  if (typeof functions !== "undefined") connectFunctionsEmulator(functions, location.hostname, 5001);
  if (typeof firestore !== "undefined") connectFirestoreEmulator(firestore, location.hostname, 8080);
}

auth.useDeviceLanguage();
export { messaging, functions, auth, firestore };
