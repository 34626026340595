document.addEventListener('alpine-i18n:locale-change', () => {
    document.documentElement.setAttribute("lang", window.AlpineI18n.locale);
    document.documentElement.setAttribute("dir", window.AlpineI18n.t("dir"));
});

document.addEventListener('alpine-i18n:ready', () => {

    let languageMode = {
        _language: Alpine.$persist(true),
        async init() {
            const response = await fetch("./language/language.json", { method: "GET", })
            if (response.ok) {
                const language = await response.json();
                window.AlpineI18n.create(this._language ? "he" : "en", language);
            }
            window.AlpineI18n.fallbackLocale = 'en';
        },
        toggle() {
            window.AlpineI18n.locale = this._language ? "he" : "en";
        }
    }
    languageMode = Alpine.reactive(languageMode)
    Alpine.data("language", () => languageMode);
});