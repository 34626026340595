document.addEventListener("alpine:init", () => {
  let notification = {
    NotificatNotificationStatusion: Notification.permission,
    init() {
      document.dispatchEvent(
        new CustomEvent("alpine:notification:init", { detail: this })
      );
    },
    requesting_permission() {
      switch (this.NotificatNotificationStatusion) {
        case "denied":
          alert(
            `Notification permission is ${this.NotificatNotificationStatusion} reset the notification permission or allow`
          );
          break;
        case "granted":
          document.dispatchEvent(new Event("notification:click"));
          alert(
            `Notification permission is already ${this.NotificatNotificationStatusion}`
          );
          break;
        default:
          console.log("Requesting permission...");
          Notification.requestPermission();
          break;
      }
    },
  };
  notification = Alpine.reactive(notification);
  Alpine.data("notification", () => notification);
});
