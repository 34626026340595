import { doc, deleteDoc } from "firebase/firestore";
import { functions, auth, firestore } from "../firebaseApp";

import { httpsCallable } from "firebase/functions";
document.addEventListener("alpine:init", () => {
  let messages = {
    arrayMessages: [],
    async deleteAllMessages() {
      const temp = this.arrayMessages
      this.arrayMessages = []
      const deleteCollection = httpsCallable(functions, "deleteCollection")
      deleteCollection({ userUid: auth.currentUser.uid }).catch((error) => {
        this.arrayMessages = temp
      });




    },


    deleteMessages(id) {
      deleteDoc(doc(firestore, "users", auth.currentUser.uid, "messags", id))
    },

    init() {
      document.addEventListener("messages:listener:NewMessages", ({ detail }) => {
        if (detail.add && !detail.new) {
          this.arrayMessages.unshift(detail.messages);
        };
        if (detail.new && !detail.add) {
          this.arrayMessages = detail.messages;
        };
      });

      document.addEventListener("deleteMessages", () => {
        this.arrayMessages = [];
      });
    }
  };
  messages = Alpine.reactive(messages);
  Alpine.data("messages", () => messages);
  document.dispatchEvent(new CustomEvent("alpine:messages:init", { detail: messages }))
});
